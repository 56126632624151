<template>
  <AppModal :show-modal="showingForm" @close-modal="closeModal">
    <template v-if="credentialFormType == 'add'" #title>
      Create Credential
    </template>
    <template v-else-if="credentialFormType == 'edit'" #title>
      Edit Credential
    </template>
    <template v-else #title> View Credential </template>

    <template #body>
      <div v-if="showLoader" class="col-12 text-center">
        <div class="spinner-border"></div>
      </div>
      <form v-else id="credentialAddEditForm" method="post" @submit.prevent>
        <input
          id="messageId"
          type="hidden"
          name="messageId"
          :value="messageId"
        />

        <input
          id="acceptReason"
          type="hidden"
          name="acceptReason"
          :value="acceptReason"
        />
        <div class="row">
          <div class="col-lg-6 mb-3">
            <label class="form-label">Users</label>
            <UsersDropDown
              :related-users="form.releted_users"
              :credential-form-type="credentialFormType"
              :master-credential-list="
                form.master_credential_list
                  ? form.master_credential_list.toLowerCase()
                  : form.master_credential_list
              "
              :credential-type="form.credential_type"
              @get-company-id="getCompanyId"
            >
            </UsersDropDown>
          </div>

          <div class="mb-3 col-lg-6">
            <label class="form-label"> Master Credential List: </label>
            <input
              v-model="form.master_credential_list"
              type="text"
              name="master_credential_list"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{ 'is-invalid': errors && errors.master_credential_list }"
              class="form-control"
            />
            <div
              v-if="errors && errors.master_credential_list"
              class="text-red mt-1 text-sm"
            >
              {{ errors.master_credential_list[0] }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 mb-3">
            <label class="form-label">Truck</label>
            <select
              ref="trucks"
              v-model="form.truck_id"
              name="truck_id"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              class="form-select"
            >
              <option value="null">--Select Truck--</option>
              <option
                v-for="truck in trucks"
                :key="truck.id"
                :value="truck.id"
                :class="{
                  'collapse hide':
                    selectedCompanyId != '' &&
                    selectedCompanyId != truck.creator_id,
                }"
              >
                {{ truck.truck_no }}
              </option>
            </select>
          </div>
          <div class="col-lg-6 mb-3">
            <label class="form-label">Equipment</label>
            <select
              ref="trucks"
              v-model="form.equipment_id"
              name="equipment_id"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              class="form-select"
            >
              <option value="null">--Select Equipment--</option>
              <option
                v-for="equipment in equipments"
                :key="equipment.id"
                :class="{
                  'collapse hide':
                    selectedCompanyId != '' &&
                    selectedCompanyId != equipment.creator_id,
                }"
                :value="equipment.id"
              >
                {{ equipment.truck_no }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-6">
            <label class="form-check form-check-inline">
              <input
                v-model="form.addFileType"
                :disabled="
                  credentialFormType == 'add' || credentialFormType == 'edit'
                    ? false
                    : true
                "
                class="form-check-input"
                type="radio"
                value="uploadFiles"
                name="addFileType"
              />
              <span class="form-check-label">Upload Files</span>
            </label>
            <label class="form-check form-check-inline">
              <input
                v-model="form.addFileType"
                :disabled="
                  credentialFormType == 'add' || credentialFormType == 'edit'
                    ? false
                    : true
                "
                value="addLinks"
                class="form-check-input"
                type="radio"
                name="addFileType"
              />
              <span class="form-check-label">Add Links</span>
            </label>

            <input
              id="documentNamesFromMessages"
              v-model="documentNamesFromMessages"
              name="documentNamesFromMessages"
              type="hidden"
            />
            <input
              v-if="form.addFileType == 'uploadFiles'"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              type="file"
              class="form-control mb-2 col-2"
              multiple
              name="documents[]"
              :class="{
                'is-invalid': errors && errors.documents,
              }"
            />

            <textarea
              v-if="form.addFileType == 'addLinks'"
              id="document_link"
              v-model="form.document_link"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              name="document_link"
              class="form-control"
            >
            </textarea>
            <small
              v-if="form.addFileType == 'addLinks'"
              data-v-54bdd0f9=""
              class="text-muted"
              >For multiple links, please separating each one with a comma
            </small>
            <div
              v-if="errors && errors['documents.0']"
              class="text-red mt-1 text-sm"
            >
              selected {{ errors['documents.0'][0] }}
            </div>
            <div
              v-if="errors && errors['documents.1']"
              class="text-red mt-1 text-sm"
            >
              {{ errors['documents.1'][0] }}
            </div>
            <div
              v-if="errors && errors['documents.2']"
              class="text-red mt-1 text-sm"
            >
              {{ errors['documents.2'][0] }}
            </div>
            <div
              v-if="errors && errors['documents.3']"
              class="text-red mt-1 text-sm"
            >
              {{ errors['documents.3'][0] }}
            </div>
            <div
              v-if="errors && errors['documents.4']"
              class="text-red mt-1 text-sm"
            >
              {{ errors['documents.4'][0] }}
            </div>
            <div
              v-if="errors && errors['documents.6']"
              class="text-red mt-1 text-sm"
            >
              {{ errors['documents.6'][0] }}
            </div>
          </div>
          <div class="mb-3 col-6">
            <div
              v-for="(document, index) in form.documents"
              :key="index"
              class="mt-1"
            >
              <a
                v-if="document.includes('drive.google.com')"
                target="_blank"
                :href="`${document}`"
                class="text-dark"
              >
                Document {{ index + 1 }}
              </a>
              <a
                v-else
                target="_blank"
                :href="`${baseUrl}/public/credential_documents/${document}`"
                class="text-dark"
              >
                Document {{ index + 1 }}
              </a>
              <a
                v-if="
                  messageId <= 0 &&
                  (credentialFormType == 'add' || credentialFormType == 'edit')
                "
                href="void:javascript(0)"
                class="ms-2"
                title="Remove Document"
                @click="removeDocument(`${document}`, form.id)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-x text-danger"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M18 6l-12 12"></path>
                  <path d="M6 6l12 12"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-6">
            <label class="form-label"> Credential Type: </label>
            <select
              ref="allUsers"
              v-model="form.credential_type"
              name="credential_type"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{ 'is-invalid': errors && errors.user_id }"
              class="form-select"
            >
              <option value="null" disabled>--Select Credential Type--</option>
              <option value="training">Training</option>
              <option value="skill set">Skill Set</option>
              <option value="body of work">Body of Work</option>
              <option value="misc">Misc</option>
              <option value="Asset">Asset</option>
            </select>
            <div
              v-if="errors && errors.credential_type"
              class="text-red mt-1 text-sm"
            >
              {{ errors.credential_type[0] }}
            </div>
          </div>

          <div class="mb-3 col-6">
            <label class="form-label"> Date of Completion: </label>
            <FlatPicker
              v-model="form.date_of_completion"
              type="text"
              name="date_of_completion"
              placeholder="--Select Date--"
              :config="formatStartDate"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{ 'is-invalid': errors && errors.date_of_completion }"
              class="form-control"
            />
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-6">
            <label class="form-label"> Number of Hours: </label>

            <input
              v-model="form.number_of_hours"
              name="number_of_hours"
              type="text"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{ 'is-invalid': errors && errors.number_of_hours }"
              class="form-control"
            />
          </div>
          <div class="mb-3 col-6">
            <label class="form-label"> Instructor's Name: </label>
            <input
              v-model="form.instructors_name"
              name="instructors_name"
              type="text"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{ 'is-invalid': errors && errors.instructors_name }"
              class="form-control"
            />
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-6">
            <label class="form-label"> License/ID Number: </label>
            <input
              v-model="form.license_number"
              name="license_number"
              type="text"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{ 'is-invalid': errors && errors.license_number }"
              class="form-control"
            />
          </div>
          <div class="mb-3 col-6">
            <label class="form-label"> Birth Date: </label>
            <FlatPicker
              v-model="form.birth_date"
              type="text"
              name="birth_date"
              placeholder="--Select Date--"
              :config="formatStartDate"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{ 'is-invalid': errors && errors.birth_date }"
              class="form-control"
            />
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-6">
            <label class="form-label"> Expiration Date: </label>
            <FlatPicker
              v-model="form.expiration_date"
              name="expiration_date"
              type="text"
              placeholder="--Select Date--"
              :config="formatStartDate"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{ 'is-invalid': errors && errors.expiration_date }"
              class="form-control"
            />
          </div>
          <div class="mb-3 col-6">
            <label class="form-label"> Certifying Authority: </label>
            <input
              v-model="form.certifying_authority"
              name="certifying_authority"
              type="text"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{ 'is-invalid': errors && errors.certifying_authority }"
              class="form-control"
            />
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-6">
            <label class="form-label"> Credential Issue Date: </label>
            <FlatPicker
              v-model="form.credential_issue_date"
              name="credential_issue_date"
              type="text"
              placeholder="--Select Date--"
              :config="formatStartDate"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{ 'is-invalid': errors && errors.credential_issue_date }"
              class="form-control"
            />
          </div>
          <div class="mb-3 col-6">
            <label class="form-label"> Training Course Description: </label>
            <textarea
              v-model="form.training_coruse_description"
              class="form-control"
              :class="{
                'is-invalid': errors && errors.training_coruse_description,
              }"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
            ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-6">
            <label class="form-label"> Education Director: </label>
            <input
              v-model="form.education_director"
              name="education_director"
              type="text"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{
                'is-invalid': errors && errors.education_director,
              }"
              class="form-control"
            />
          </div>
          <div class="mb-3 col-6">
            <label class="form-label"> President: </label>
            <input
              v-model="form.president"
              name="president"
              type="text"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{
                'is-invalid': errors && errors.president,
              }"
              class="form-control"
            />
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-6">
            <label class="form-label"> Course Number: </label>
            <input
              v-model="form.course_number"
              name="course_number"
              type="text"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{
                'is-invalid': errors && errors.course_number,
              }"
              class="form-control"
            />
          </div>
          <div class="mb-3 col-6">
            <label class="form-label"> Training Class/Handling Permits: </label>
            <input
              v-model="form.training_class"
              name="training_class"
              type="text"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{
                'is-invalid': errors && errors.training_class,
              }"
              class="form-control"
            />
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-6">
            <label class="form-label"> Version: </label>
            <input
              v-model="form.version"
              name="version"
              type="text"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{
                'is-invalid': errors && errors.version,
              }"
              class="form-control"
            />
          </div>

          <div class="mb-3 col-6">
            <label class="form-label"> Deprecated: </label>
            <input
              v-model="form.deprecated"
              name="deprecated"
              type="text"
              :disabled="
                credentialFormType == 'add' || credentialFormType == 'edit'
                  ? false
                  : true
              "
              :class="{
                'is-invalid': errors && errors.deprecated,
              }"
              class="form-control"
            />
          </div>
        </div>
      </form>
    </template>
    <template
      v-if="credentialFormType == 'add' || credentialFormType == 'edit'"
      #footer
    >
      <hr />
      <div style="width: 100%">
        <a class="btn btn-link link-secondary" @click="closeModal">{{
          changed ? 'Cancel' : 'Close'
        }}</a>
        <a
          class="btn btn-primary ms-auto"
          :class="{ disabled: submitting }"
          @click="submit"
          >{{
            credentialFormType == 'add'
              ? submitting
                ? 'Creating'
                : 'Create'
              : submitting
              ? 'Updating'
              : 'Update'
          }}
          <span
            v-if="submitting"
            class="fa fa-circle-notch ms-1 fa-spin ml-3"
          />
        </a>
      </div>
    </template>
  </AppModal>
</template>

<script lang="ts">
import AppModal from '@components/common/AppModal.vue';
import axios from 'axios';
import FlatPicker from 'vue-flatpickr-component';
import { mapActions } from 'vuex';
import Errors from '@components/Errors.vue';
import 'flatpickr/dist/flatpickr.css';
import UsersDropDown from '@components/admin/UsersDropDown.vue';

export default {
  name: 'CredentialFormModal',
  components: {
    AppModal,
    FlatPicker,
    Errors,
    UsersDropDown,
  },
  props: {
    showingForm: { type: Boolean, default: false },
    getDocumentRequests: { type: Boolean, default: false },
    newCredential: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    credentialView: { type: Boolean, default: true },
    credentialFormType: { type: Boolean, default: true },
    allUsers: { type: Boolean, default: true },
    trucks: { type: Boolean, default: true },
    equipments: { type: Boolean, default: true },
    credentialEditing: { type: Boolean, default: true },
    messageId: { type: Number, default: 0 },
    acceptReason: { type: String, default: '' },
    logUser: {
      type: Array,
      default() {
        return [];
      },
    },
    getUserIds: {
      type: Array,
      default() {
        return [];
      },
    },
    users: {
      type: Array,
      default() {
        return [];
      },
    },
    states: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup() {
    return {};
  },
  data() {
    return {
      baseUrl: window.location.origin,
      selectedCompanyId: '',
      getUsers: this.$props.getUserIds,
      showLoader: false,
      formatStartDate: {
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'd M Y',
      },
      form: {
        name: null,
        creator_id: null,
        state: null,
        city: null,
        address: null,
        zip_code: null,
        documents: null,
        document_link: null,
        addFileType: 'uploadFiles',
      },
      documentNamesFromMessages: null,
      submitting: false,
      changed: false,
      ready: false,
      cities: [],
    };
  },
  computed: {},
  created() {},
  mounted() {
    if (this.messageId > 0) {
      this.loadDetailsFromMessage();
    }
    if (this.credentialView) {
      this.viewCredential();
    }
  },
  beforeUnmount() {},
  updated() {},
  methods: {
    ...mapActions({
      popToast: 'toast/popToast',
    }),

    async submit() {
      this.error = null;
      this.submitting = true;

      if (this.credentialFormType == 'add') {
        await this.createCredential();
      } else if (this.credentialFormType == 'edit') {
        await this.updateCredential();
      }
      this.submitting = false;
    },
    closeModal() {
      this.$emit('closeModal');
    },
    viewCredential() {
      this.showLoader = true;
      axios
        .get(`api/credentials/${this.credentialView}/edit`)
        .then((response) => {
          this.form = response.data.credential;
          this.form.addFileType = 'uploadFiles';
          this.showLoader = false;
        });
    },
    async createCredential() {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const form = document.getElementById('credentialAddEditForm');
      await axios
        .post('api/credentials', new FormData(form), config)
        .then(({ data }) => {
          if (this.messageId > 0) {
            this.getDocumentRequests();
          }
          const self = this;
          self.$swal.fire(
            'Thank you!',
            'Thank you for successfully submiting your request. Our team will process your request as soon as possible',
            'success',
          );
          this.submitting = false;
          this.$emit('locationCreated');
          this.closeModal();
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            const { errors } = response.data;
            this.errors = errors;
          } else if (response.status === 415 || response.status === 413) {
            const { errors } = response.data;
            this.errors = errors;
            this.popToast({
              message:
                'You are allowed to upload files of 50 MB max at a time. Please email the file(s) to info@towamericateam.com',
              timer: 5000,
              icon: 'error',
            });
          } else {
            this.popToast({
              message: 'Error Creating Credential',
              timer: 5000,
              icon: 'error',
            });
          }
          this.submitting = false;
        });

      this.submitting = false;
    },
    async updateCredential() {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const form = document.getElementById('credentialAddEditForm');
      const formData = new FormData(form);
      formData.append(
        'training_coruse_description',
        this.form.training_coruse_description,
      );
      await axios
        .post(
          `/api/credentials/update-credentials/${this.form.id}`,
          formData,
          config,
        )
        .then(({ data }) => {
          this.$emit('locationUpdated');
          const self = this;
          self.$swal.fire(
            'Thank you!',
            'Thank you for successfully submiting your request. Our team will process your request as soon as possible',
            'success',
          );
          this.submitting = false;
          this.closeModal();
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            const { errors } = response.data;

            this.errors = errors;
          } else if (response.status === 415 || response.status === 413) {
            const { errors } = response.data;
            this.errors = errors;
            this.popToast({
              message:
                'You are allowed to upload files of 50 MB max at a time. Please email the file(s) to info@towamericateam.com',
              timer: 5000,
              icon: 'error',
            });
          } else {
            this.popToast({
              message: 'Error Updating Credential',
              timer: 5000,
              icon: 'error',
            });
          }
          this.submitting = false;
        });
      this.submitting = false;
    },
    getFirstChar(firstName, lastName) {
      const firstsubstr = firstName.substring(0, 1);
      const lastsubstr = lastName.substring(0, 1);
      return firstsubstr + lastsubstr;
      // }
    },
    removeDocument(imageName, credentialId) {
      if (confirm(`Are you sure you want to delete this document?`) == true) {
        const postData = {
          credentialId: credentialId,
          imageName: imageName,
        };
        axios
          .post('/api/credentials/delete-document', postData)
          .then(({ data }) => {
            this.popToast({
              message: 'Document Deleted!',
              timer: 5000,
              icon: 'success',
            });
            this.form.documents = data;
            this.submitting = false;
          })
          .catch(({ response }) => {
            if (response.status === 422) {
              const { errors } = response.data;
              this.errors = errors;
            } else {
              this.popToast({
                message: 'Error Deleting Document!',
                timer: 5000,
                icon: 'error',
              });
            }
            this.submitting = false;
          });
      }
    },
    loadDetailsFromMessage() {
      axios
        .get('api/getDetailsFromMessage/' + this.messageId)
        .then((response) => {
          this.form.credential_type = response.data.credential_type;
          this.form.releted_users = response.data.releted_users;
          this.form.documents = response.data.documents;
          this.form.master_credential_list = response.data.master_credential;
          this.documentNamesFromMessages = response.data.documents;
        });
    },
    getCompanyId(companyId) {
      this.selectedCompanyId = companyId;
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
:deep() .multiselect-option {
  background: transparent;
  color: #777;
}
:deep() .multiselect-option.is-pointed {
  background: #10b981;
  color: #ffffff;
}
.modal-header {
  background-color: #f7f4f4;
}
</style>
