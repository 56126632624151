<template>
  <div class="min-h-screen bg-white dark:bg-slate-800">
    <div id="top_border" />
    <AppNav />
    <div class="w-full">
      <router-view v-slot="{ Component }" :class="isAdminPage ? '' : ''">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <AppFooter v-if="!isAdminPage" class="sticky top-[100vh] w-full" />
    <AppToast />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppNav from '@components/AppNav.vue';
import AppFooter from '@components/AppFooter.vue';

export default {
  components: {
    AppNav,
    AppFooter,
  },
  computed: {
    ...mapGetters({
      darkMode: 'auth/darkMode',
    }),
    currentRouteName() {
      return this.$route.name;
    },
    isAdminPage() {
      if (
        this.currentRouteName == 'admin' ||
        this.currentRouteName == 'roles' ||
        this.currentRouteName == 'permissions' ||
        this.currentRouteName == 'settings' ||
        this.currentRouteName == 'users' ||
        this.currentRouteName == 'trucks' ||
        this.currentRouteName == 'locations' ||
        this.currentRouteName == 'equipment' ||
        this.currentRouteName == 'app-settings' ||
        this.currentRouteName == 'phpinfo' ||
        this.currentRouteName == 'credentials' ||
        this.currentRouteName == 'development_board' ||
        this.currentRouteName == 'cities' ||
        this.currentRouteName == 'states' ||
        this.currentRouteName == 'users-list' ||
        this.currentRouteName == 'proofspace-users' ||
        this.currentRouteName == 'support-management' ||
        this.currentRouteName == 'support' ||
        this.currentRouteName == 'report' ||
        this.currentRouteName == 'support-categories' ||
        this.currentRouteName == 'skillsets' ||
        this.currentRouteName == 'designations' ||
        this.currentRouteName == 'message-center' ||
        this.currentRouteName == 'notifications' ||
        this.currentRouteName == 'tasks' ||
        this.currentRouteName == 'activity-reports'
      ) {
        return true;
      }
      return false;
    },
    showOctoCat() {
      if (
        this.currentRouteName == 'home' ||
        this.currentRouteName == 'about' ||
        this.currentRouteName == 'terms'
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      popToast: 'toast/popToast',
    }),
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
