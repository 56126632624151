import { createApp } from 'vue';
import App from '@views/App.vue';
import router from '@router';
import store from '@store';
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import { plugin as VueTippy } from 'vue-tippy';
import AppButton from '@components/common/AppButton.vue';
import AppToast from '@components/common/AppToast.vue';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { registerSW } from 'virtual:pwa-register';
import VueSecureHTML from 'vue-html-secure';

axios.defaults.withCredentials = true;

axios.defaults.baseURL = APP_URL + '/';

const app = createApp(App);
const VUE_APP_URL = APP_URL; // eslint-disable-line
const VUE_ENVIRONMENT = ENVIRONMENT; // eslint-disable-line

const updateSW = registerSW({
  onOfflineReady() {},
});

store.dispatch('auth/getLogins').then(() => {});
store.dispatch('auth/getUser').then(() => {
  app
    .use(store)
    .use(router)
    .use(VueSweetalert2)
    .use(VueTippy, {
      directive: 'tippy', // => v-tippy
      component: 'tippy', // => <tippy/>
      componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
      defaultProps: {
        arrow: true,
        theme: 'material',
        animation: 'perspective',
        placement: 'auto-end',
        allowHTML: true,
      },
    })
    .use(VueSecureHTML)
    .component('AppButton', AppButton)
    .component('AppToast', AppToast);

  app.mount('#app');
});
