<template>
  <div
    v-if="showModal"
    id="modal-report"
    class="modal modal-blur fade"
    :class="{ show: showModal == true }"
    :style="displayModal"
    role="dialog"
    tabindex="-1"
    aria-modal="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">
            <slot v-if="hasTitleSlot" name="title" />
          </h3>
          <AppButton
            v-if="showClose"
            text="X"
            class="text-1xl float-right border-0 bg-transparent text-gray-600 shadow-none outline-none hover:text-gray-800 focus:outline-none"
            @click="closeModal()"
          />
        </div>
        <div class="modal-body bg-light">
          <slot v-if="hasBodySlot" name="body" />
        </div>
        <div v-if="hasFooterSlot" class="modal-footer bg-light">
          <slot v-if="hasFooterSlot" name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppModal',
  props: {
    showModal: { type: Boolean, default: false },
    showClose: { type: Boolean, default: true },
  },
  data() {
    return {
      displayModal: {
        display: 'block',
      },
    };
  },
  computed: {
    hasTitleSlot() {
      return this.$slots.title;
    },
    hasBodySlot() {
      return this.$slots.body;
    },
    hasFooterSlot() {
      return this.$slots.footer;
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.modal-blur {
  background: rgb(112 112 112 / 20%);
  backdrop-filter: blur(3px);
}
</style>
