<template>
  <AppModal :show-modal="showingForm" @close-modal="closeModal">
    <template #title> Manage Development </template>
    <template #body>
      <div v-if="formData.skillset_type == 'Custom'" class="mb-3">
        <label class="form-label">Name</label>
        <input
          v-model="formData.name"
          :class="{ 'is-invalid': errors && errors.name }"
          :disabled="formData.selectSkillSet != '' ? true : false"
          type="text"
          class="form-control"
          placeholder="Your skill set name"
        />
        <div v-if="errors && errors.name" class="text-red mt-1 text-sm">
          {{ errors.name[0] }}
        </div>
      </div>
      <div v-if="formData.skillset_type == 'Pre-Loaded'" class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <label class="form-label">Skill Set Selection</label>
            <small class="text-muted"
              >Hold the <b> CTRL </b>key and click the items in a list to choose
            </small>

            <select
              v-model="formData.selectSkillSet"
              class="form-select"
              :class="{ 'is-invalid': errors && errors.selectSkillSet }"
              multiple
              @change="changeSkillSet"
            >
              <option value="" disabled>--Select Skill Set--</option>
              <option
                v-for="skillsetOption in skillSetOptions"
                :key="skillsetOption.id"
                :value="skillsetOption.name"
              >
                {{ skillsetOption.name }}
              </option>
            </select>
            <div
              v-if="errors && errors.selectSkillSet"
              class="text-red mt-1 text-sm"
            >
              {{ errors.selectSkillSet[0] }}
            </div>
          </div>
        </div>
      </div>
      <label class="form-label">Skill Set Type</label>
      <div class="form-selectgroup-boxes row mb-3">
        <div class="col-lg-6">
          <label class="form-selectgroup-item">
            <input
              v-model="formData.skillset_type"
              type="radio"
              value="Pre-Loaded"
              class="form-selectgroup-input"
              checked=""
            />
            <span class="form-selectgroup-label d-flex align-items-center p-3">
              <span class="me-3">
                <span class="form-selectgroup-check"></span>
              </span>
              <span class="form-selectgroup-label-content">
                <span class="form-selectgroup-title strong mb-1"
                  >Pre-Loaded</span
                >
                <span class="d-block text-muted"
                  >Skill set type that is already created in the system.</span
                >
              </span>
            </span>
          </label>
        </div>
        <div class="col-lg-6">
          <label class="form-selectgroup-item">
            <input
              v-model="formData.skillset_type"
              type="radio"
              name="report-type"
              value="Custom"
              class="form-selectgroup-input"
            />
            <span class="form-selectgroup-label d-flex align-items-center p-3">
              <span class="me-3">
                <span class="form-selectgroup-check"></span>
              </span>
              <span class="form-selectgroup-label-content">
                <span class="form-selectgroup-title strong mb-1">Custom</span>
                <span class="d-block text-muted"
                  >Upload a new skill set type and we'll discuss adding it to
                  the system.</span
                >
              </span>
            </span>
          </label>
        </div>
      </div>
    </template>
    <template #footer>
      <div style="width: 100%">
        <a href="#" class="btn btn-link link-secondary" @click="closeModal">
          Close
        </a>
        <a
          href="#"
          class="btn btn-danger ms-2"
          :class="{
            'collapse hide': logUser.roles[0].slug == 'super.admin',
          }"
          @click="removeSkillSet"
        >
          Remove
        </a>
        <a
          href="#"
          class="btn btn-primary ms-2"
          data-bs-dismiss="modal"
          @click="submit"
        >
          {{ formData.skillset_type == 'Custom' ? 'Request' : 'Create' }}
          <span
            v-if="submitting"
            class="fa fa-circle-notch ms-1 fa-spin ml-3"
          />
        </a>
      </div>
    </template>
  </AppModal>
</template>

<script lang="ts">
import AppModal from '@components/common/AppModal.vue';
import axios from 'axios';
import { mapActions } from 'vuex';
import Errors from '@components/Errors.vue';
import bus from 'vue3-eventbus';

export default {
  name: 'SkillSetFormModal',
  components: {
    AppModal,
    Errors,
  },
  props: {
    showingForm: { type: Boolean, default: false },
    newLocation: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    credentialView: { type: Boolean, default: true },
    logUser: {
      type: Array,
      default() {
        return [];
      },
    },
    users: {
      type: Array,
      default() {
        return [];
      },
    },
    states: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup() {
    return {};
  },
  data() {
    return {
      formData: {
        name: null,
        selectSkillSet: '',
        skillset_type: 'Pre-Loaded',
      },
      showUploadCredentialForm: false,
      skillSetOptions: [],
      submitting: false,
      errors: null,
      changed: false,
      ready: false,
      cities: [],
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.loadSkillSetOptions();
  },
  beforeUnmount() {},
  updated() {},
  methods: {
    ...mapActions({
      popToast: 'toast/popToast',
    }),
    async submit() {
      this.error = null;
      this.submitting = true;
      await this.createSkillset();
      this.submitting = false;
    },
    async createSkillset() {
      await axios
        .post('/api/skillSet', this.formData)
        .then(({ data }) => {
          if (this.formData.skillset_type == 'Custom') {
            const self = this;
            self.$swal.fire(
              'Thank you!',
              'Thank you for successfully submiting your request. Our team will process your request as soon as possible',
              'success',
            );
          } else {
            this.popToast({
              message: 'Skill Set Added Successfully!',
              timer: 5000,
              icon: 'success',
            });
            this.$emit('getDevelopmentBoard');
          }
          this.submitting = false;
          bus.emit('get-dashboard');
          this.closeModal();
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            const { errors } = response.data;
            this.errors = errors;
          } else {
            this.popToast({
              message: 'Error Adding Skill Set',
              timer: 5000,
              icon: 'error',
            });
          }
          this.submitting = false;
        });

      this.submitting = false;
    },
    closeModal() {
      this.formData.name = null;
      this.formData.selectSkillSet = '';
      this.formData.skillset_type = 'Pre-Loaded';

      this.$emit('closeModal');
    },
    loadSkillSetOptions() {
      axios.get(`api/getSkillSetOptions`).then((response) => {
        this.skillSetOptions = response.data;
      });
    },
    changeSkillSet() {
      if (this.formData.selectSkillSet != '') {
        this.formData.name = this.formData.selectSkillSet;
      }
    },
    removeSkillSet() {
      if (this.formData.selectSkillSet != '') {
        axios
          .post('api/removeSkillSet', this.formData.selectSkillSet)
          .then((response) => {
            this.popToast({
              message: 'Development Removed Successfully!',
              timer: 5000,
              icon: 'success',
            });
            this.closeModal();
          });
      } else {
        this.popToast({
          message: 'Please Select Skill Set',
          timer: 5000,
          icon: 'error',
        });
      }
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
:deep() .multiselect-option {
  background: transparent;
  color: #777;
}
:deep() .multiselect-option.is-pointed {
  background: #10b981;
  color: #ffffff;
}
.modal-header {
  background-color: #f7f4f4;
}
</style>
