<template>
  <AppModal :show-modal="showingForm" @close-modal="closeModal">
    <template #title> Upload Files </template>
    <template #body>
      <form id="uploadDocumentForm" method="post" @submit.prevent>
        <div class="row">
          <div class="mb-3 col-md-6 col-sm-12">
            <label class="form-label">Upload Files</label>
            <input
              type="file"
              class="form-control mb-2 col-2"
              multiple
              name="documents[]"
              :class="{
                'is-invalid': errors && errors.documents,
              }"
            />
            <div
              v-if="errors && errors['documents.0']"
              class="text-red mt-1 text-sm"
            >
              First selected {{ errors['documents.0'][0] }}
            </div>
            <div
              v-if="errors && errors['documents.1']"
              class="text-red mt-1 text-sm"
            >
              Second {{ errors['documents.1'][0] }}
            </div>
            <div
              v-if="errors && errors['documents.2']"
              class="text-red mt-1 text-sm"
            >
              Third {{ errors['documents.2'][0] }}
            </div>
            <div
              v-if="errors && errors['documents.3']"
              class="text-red mt-1 text-sm"
            >
              Fourth {{ errors['documents.3'][0] }}
            </div>
            <div
              v-if="errors && errors['documents.4']"
              class="text-red mt-1 text-sm"
            >
              Fifth {{ errors['documents.4'][0] }}
            </div>
            <div
              v-if="errors && errors['documents.6']"
              class="text-red mt-1 text-sm"
            >
              {{ errors['documents.6'][0] }}
            </div>
          </div>
          <div class="mb-3 col-md-6 col-sm-12">
            <label class="form-label"> Credential Type: </label>
            <select
              ref="allUsers"
              v-model="formData.credential_type"
              name="credential_type"
              :class="{ 'is-invalid': errors && errors.user_id }"
              class="form-select"
            >
              <option value="" disabled>--Select Credential Type--</option>
              <option value="training">Training</option>
              <option value="skill set">Skill Set</option>
              <option value="body of work">Body of Work</option>
              <option value="misc">Misc</option>
            </select>
            <div
              v-if="errors && errors.credential_type"
              class="text-red mt-1 text-sm"
            >
              {{ errors.credential_type[0] }}
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <div style="width: 100%">
        <a href="#" class="btn btn-link link-secondary" @click="closeModal">
          Close
        </a>
        <a
          href="#"
          class="btn btn-primary ms-auto"
          data-bs-dismiss="modal"
          @click="submit"
        >
          Upload
          <span
            v-if="submitting"
            class="fa fa-circle-notch ms-1 fa-spin ml-3"
          />
        </a>
      </div>
    </template>
  </AppModal>
</template>

<script lang="ts">
import AppModal from '@components/common/AppModal.vue';
import axios from 'axios';
import { mapActions } from 'vuex';
import Errors from '@components/Errors.vue';
import bus from 'vue3-eventbus';

export default {
  name: 'UploadDocumentModal',
  components: {
    AppModal,
    Errors,
  },
  props: {
    showingForm: { type: Boolean, default: false },
  },
  data() {
    return {
      formData: {
        credential_type: '',
        documents: '',
      },
      submitting: false,
      errors: null,
    };
  },
  methods: {
    ...mapActions({
      popToast: 'toast/popToast',
    }),

    async submit() {
      this.error = null;
      this.submitting = true;
      await this.uploadDocument();
      this.submitting = false;
    },

    async uploadDocument() {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const form = document.getElementById('uploadDocumentForm');
      const formData = new FormData(form);
      await axios
        .post('api/uploadDocument', new FormData(form), config)
        .then(({ data }) => {
          this.$swal.fire(
            'Thank you!',
            'Document uploaded successfully.',
            'success',
          );
          this.$emit('close-modal');
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            const { errors } = response.data;
            this.errors = errors;
          } else if (response.status === 415 || response.status === 413) {
            const { errors } = response.data;
            this.errors = errors;
            this.popToast({
              message:
                'You are allowed to upload files of 50 MB max at a time. Please email the file(s) to info@towamericateam.com',
              timer: 5000,
              icon: 'error',
            });
          } else {
            this.popToast({
              message:
                'You are allowed to upload files of 50 MB max at a time. Please email the file(s) to info@towamericateam.com',
              timer: 5000,
              icon: 'error',
            });
          }
        });
      this.submitting = false;
    },

    closeModal() {
      this.formData.credential_type = '';
      this.formData.documents = '';
      this.$emit('closeModal');
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
:deep() .multiselect-option {
  background: transparent;
  color: #777;
}
:deep() .multiselect-option.is-pointed {
  background: #10b981;
  color: #ffffff;
}
.modal-header {
  background-color: #f7f4f4;
}
</style>
