<template>
  <div class="col-6 float-start">
    <select
      v-model="company_dropdown"
      :disabled="
        (credFormType == 'add' || credFormType == 'edit') &&
        masterCredentialList != 'truck inspection' &&
        credentialType != 'Asset'
          ? false
          : true
      "
      class="form-select mb-1 col-6"
      @change="$emit('getCompanyId', company_dropdown)"
    >
      <option value="">Sort by Company</option>
      <option
        v-for="userDetail in allUsers"
        :key="userDetail.user_id"
        :value="userDetail.user_id"
        :class="{
          'collapse hide': userDetail.slug != 'company',
        }"
      >
        {{ userDetail.company_name }}
      </option>
    </select>
  </div>
  <div class="float-start ms-1 input-search">
    <input
      ref="userSearchBox"
      v-model="searchUser"
      :disabled="
        masterCredentialList != 'truck inspection' && credentialType != 'Asset'
          ? false
          : true
      "
      type="search"
      class="form-control d-inline-block me-1"
      placeholder="Search user…"
    />
  </div>
  <select
    v-model="relevantUsers"
    multiple="true"
    name="user_id[]"
    :disabled="
      (credFormType == 'add' || credFormType == 'edit') &&
      masterCredentialList != 'truck inspection' &&
      credentialType != 'Asset'
        ? false
        : true
    "
    :class="{ 'is-invalid': errors && errors.user_id }"
    class="form-select select-user-dropdown"
  >
    <option value="null" disabled>--Select Users--</option>
    <option
      v-for="user in companyUsers"
      :key="user.id"
      :class="{
        'collapse hide':
          user.slug == 'company' ||
          (company_dropdown != '' && company_dropdown != user.company_id) ||
          (searchUser != '' &&
            !user.first_name.toLowerCase().match(searchUser.toLowerCase()) &&
            !user.last_name.toLowerCase().match(searchUser.toLowerCase())),
      }"
      :value="user.user_id"
    >
      {{ user.first_name }} {{ user.last_name }}
    </option>
  </select>
  <div v-if="errors && errors.name" class="text-red mt-1 text-sm">
    {{ errors.name[0] }}
  </div>
  <div v-if="errors && errors.user_id" class="text-red mt-1 text-sm">
    {{ errors.user_id[0] }}
  </div>
</template>

<script lang="ts">
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';

export default {
  name: 'UsersTable',
  components: {},
  props: {
    dataReady: { type: Boolean, default: false },
    lockJiggled: { type: Boolean, default: false },
    credentialFormType: { type: Boolean, default: false },
    credentialType: { type: Boolean, default: true },
    masterCredentialList: { type: Boolean, default: true },
    relatedUsers: {
      type: Array,
      default() {
        return [];
      },
    },
    users: {
      type: Array,
      default() {
        return [];
      },
    },
    pagination: {
      type: Object,
      default() {
        return {};
      },
    },
    availableRoles: { type: Array, default: null },
  },
  setup() {
    return {};
  },
  data() {
    return {
      offset: 4,
      allUsers: [],
      companyUsers: [],
      searchUser: '',
      company_dropdown: '',
      relevantUsers: this.$props.relatedUsers,
      credFormType: this.$props.credentialFormType,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      roles: 'auth/roles',
    }),
  },
  watch: {
    relatedUsers: function () {
      this.relevantUsers = this.$props.relatedUsers;
    },
  },
  created() {},
  mounted() {
    this.getUsers();
  },
  beforeUnmount() {},
  updated() {},
  methods: {
    ...mapActions({
      popToast: 'toast/popToast',
    }),
    getUsers() {
      axios
        .get(`api/users?per_page=all&company_dropdown=${this.company_dropdown}`)
        .then((response) => {
          this.companyUsers = response.data.user.data;
          this.allUsers = response.data.allUserRoles;
        });
    },
  },
};
</script>
<style>
.input-search {
  width: 48%;
}
.select-user-dropdown {
  height: 350px;
}
</style>
