<template>
  <header
    v-if="authenticated"
    class="navbar navbar-expand-md navbar-light d-print-none"
  >
    <div class="container-xl">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbar-menu"
        aria-controls="navbar-menu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1
        class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-md-3 pe-0"
      >
        <!-- <router-link class="nav-link" :to="{ name: 'dashboard' }">
          <img src="@img/logo.webp" height="32" alt="" />
        </router-link> -->
      </h1>

      <div class="navbar-nav order-md-last flex-row">
        <div class="nav-item d-none d-md-flex me-3">
          <div class="btn-list"></div>
        </div>

        <div
          v-if="user.super_admin_id > 0"
          class="mt-3 me-4 cursor-pointer"
          title="Back To Super Admin"
          @click="backToSuperAdmin()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-arrow-back-up"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M9 14l-4 -4l4 -4"></path>
            <path d="M5 10h11a4 4 0 1 1 0 8h-1"></path>
          </svg>
        </div>
        <div class="float-end me-2 mt-2"></div>
        <ul class="navbar-nav d-block">
          <li class="nav-item dropdown cursor-pointer w-auto float-start mt-2">
            <img
              v-if="
                user.image != '' &&
                user.image != null &&
                user.profile_picture_exist
              "
              class="avatar avatar-sm"
              :src="`${baseUrl}/public/images/user_images/${user.image}`"
            />
          </li>
          <li class="nav-item dropdown cursor-pointer w-auto float-start mt-2">
            <div class="nav-item dropdown d-md-flex">
              <a
                v-if="user.roles[0].slug != 'super.admin'"
                id="notification-button"
                href="#"
                class="nav-link px-0 notification-button"
                data-bs-toggle="dropdown"
                tabindex="-1"
                aria-label="Show notifications"
                @click="notificationDropdownchange"
              >
                <!-- Download SVG icon from http://tabler-icons.io/i/bell -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"
                  />
                  <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                </svg>
                <span
                  v-if="user.notification_unread_counter > 0"
                  class="badge bg-red badge-pill text-white"
                  >{{ user.notification_unread_counter }}</span
                >
              </a>
              <div
                class="dropdown-menu dropdown-menu-arrow dropdown-menu-card notification-dropdown"
              >
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Notifications</h3>
                  </div>
                  <div
                    id="notification-list"
                    class="list-group list-group-flush list-group-hoverable"
                  >
                    <div
                      v-if="user.notifications.length <= 0"
                      class="list-group-item"
                    >
                      <div class="col">You don't have any notifications.</div>
                    </div>
                    <div
                      v-for="notification in user.notifications"
                      :key="notification.id"
                      class="list-group-item"
                      :class="{
                        active: notification.is_read == 0,
                      }"
                    >
                      <div class="row align-items-center notification-row">
                        <div class="col">
                          <div
                            class="text-body d-block col-6 text-start float-start"
                          >
                            {{ notification.title }}
                          </div>
                          <div
                            class="text-body d-block col-6 text-end float-end text-muted"
                          >
                            {{ timeFormat(notification.created_at) }}
                          </div>
                          <div
                            v-html-safe="notification.description"
                            class="d-block text-muted mt-n1 mt-4"
                          ></div>
                        </div>
                        <div class="col-auto"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown cursor-pointer w-auto float-start mt-2">
            <div class="nav-item dropdown d-md-flex me-2">
              <router-link
                class="nav-link px-0"
                :to="{ name: 'message-center' }"
                @click="unreadBedge"
              >
                <!-- Download SVG icon from http://tabler-icons.io/i/bell -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-mail"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"
                  ></path>
                  <path d="M3 7l9 6l9 -6"></path>
                </svg>
                <span
                  v-if="user.unread_message_counter > 0"
                  class="badge bg-red badge-pill text-white"
                  >{{ user.unread_message_counter }}</span
                >
              </router-link>
            </div>
          </li>
          <li class="nav-item dropdown cursor-pointer w-auto float-start">
            <div class="nav-link" data-bs-toggle="dropdown">
              <span class="nav-link-title">
                <div class="text-capitalize dropdown-toggle">
                  Welcome,
                  {{
                    user && user.first_name
                      ? ' ' + user.first_name + ' ' + user.last_name
                      : ''
                  }}
                </div>
                <div class="small text-muted mt-1">
                  {{ user && user.roles[0] ? ' ' + user.roles[0].name : '' }}
                </div>
              </span>
            </div>
            <div class="dropdown-menu">
              <router-link
                :to="{ name: 'dashboard' }"
                class="dropdown-item"
                :class="{
                  active: $route.name == 'dashboard',
                }"
              >
                Dashboard
              </router-link>
              <router-link
                :to="{ name: 'settings' }"
                class="dropdown-item"
                :class="{
                  active: $route.name == 'profile',
                }"
              >
                Profile
              </router-link>
              <div
                class="dropdown-item text-decoration-none text-black"
                @click.prevent="logout()"
              >
                Logout
              </div>
              <router-link
                :to="{ name: 'support' }"
                class="dropdown-item"
                :class="{
                  active: $route.name == 'support',
                }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-help nav-link-icon"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                  <path d="M12 17l0 .01"></path>
                  <path
                    d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4"
                  ></path>
                </svg>
                Support
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
  <header class="navbar navbar-expand-md navbar-light d-print-none pb-1 pt-2">
    <div class="container-xl justify-content-end">
      <div class="navbar-nav order-md-last flex-row">
        <div class="nav-item d-none d-md-flex me-3">
          <div class="btn-list"></div>
        </div>

        <div
          v-if="
            authenticated &&
            (user.roles[0].slug == 'super.admin' ||
              user.roles[0].slug == 'admin')
          "
          class="float-end me-2"
        >
          <a
            class="btn btn-outline-primary w-100"
            @click="getDocumentRequests()"
          >
            View File Requests
          </a>
        </div>
        <div
          v-if="authenticated && user.roles[0].slug != 'super.admin'"
          class="float-end me-2"
        >
          <a
            class="btn btn-outline-primary w-100"
            @click="showUploadDocument = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1="12" y1="5" x2="12" y2="19" />
              <line x1="5" y1="12" x2="19" y2="12" />
            </svg>
            Upload Files
          </a>
        </div>
      </div>
    </div>
  </header>
  <header v-if="authenticated" class="navbar-expand-md">
    <div id="navbar-menu" class="navbar-collapse collapse">
      <div class="navbar navbar-light">
        <div class="container-xl">
          <ul class="navbar-nav col-12">
            <li
              class="nav-item"
              :class="{ active: $route.name == 'dashboard' }"
            >
              <router-link class="nav-link" :to="{ name: 'dashboard' }">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                </svg>

                <span class="nav-link-title"> Dashboard </span>
              </router-link>
            </li>
            <li
              v-if="
                user.roles[0].slug == 'admin' ||
                user.roles[0].slug == 'manager' ||
                user.roles[0].slug == 'super.admin' ||
                user.roles[0].slug == 'super.user'
              "
              class="nav-item dropdown cursor-pointer"
              :class="{
                active: $route.name == 'users' || $route.name == 'users-list',
              }"
            >
              <div class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-users"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                </svg>

                <span class="nav-link-title">Users</span>
              </div>
              <div class="dropdown-menu" data-bs-popper="none">
                <div class="dropdown-menu-column">
                  <div class="dropend">
                    <router-link
                      class="dropdown-item"
                      :class="{
                        active: $route.name == 'users',
                      }"
                      :to="{ name: 'users' }"
                      >Grid View</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      :class="{
                        active: $route.name == 'users-list',
                      }"
                      :to="{ name: 'users-list' }"
                      >List View</router-link
                    >
                    <router-link
                      v-if="user.roles[0].slug == 'super.admin'"
                      class="dropdown-item"
                      :class="{
                        active: $route.name == 'proofspace-users',
                      }"
                      :to="{ name: 'proofspace-users' }"
                      >ProofSpace Users</router-link
                    >
                  </div>
                </div>
              </div>
            </li>
            <li
              v-if="
                user.roles[0].slug == 'super.admin' ||
                user.roles[0].slug == 'manager' ||
                user.roles[0].slug == 'admin' ||
                user.roles[0].slug == 'super.user'
              "
              class="nav-item dropdown cursor-pointer"
              :class="{
                active: $route.name == 'trucks' || $route.name == 'equipment',
              }"
            >
              <div class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-asset"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 15m-6 0a6 6 0 1 0 12 0a6 6 0 1 0 -12 0"></path>
                  <path d="M9 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M19 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M14.218 17.975l6.619 -12.174"></path>
                  <path d="M6.079 9.756l12.217 -6.631"></path>
                  <path d="M9 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                </svg>

                <span class="nav-link-title">Assets</span>
              </div>
              <div class="dropdown-menu" data-bs-popper="none">
                <div class="dropdown-menu-column">
                  <div class="dropend">
                    <router-link
                      class="dropdown-item"
                      :class="{
                        active: $route.name == 'trucks',
                      }"
                      :to="{ name: 'trucks' }"
                      >Trucks</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      :class="{
                        active: $route.name == 'equipment',
                      }"
                      :to="{ name: 'equipment' }"
                      >Equipment</router-link
                    >
                  </div>
                </div>
              </div>
            </li>
            <li
              v-if="
                user.roles[0].slug == 'admin' ||
                user.roles[0].slug == 'super.admin'
              "
              class="nav-item dropdown cursor-pointer"
              :class="{
                active:
                  $route.name == 'locations' ||
                  $route.name == 'states' ||
                  $route.name == 'cities',
              }"
            >
              <div class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-asset"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 15m-6 0a6 6 0 1 0 12 0a6 6 0 1 0 -12 0"></path>
                  <path d="M9 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M19 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M14.218 17.975l6.619 -12.174"></path>
                  <path d="M6.079 9.756l12.217 -6.631"></path>
                  <path d="M9 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                </svg>

                <span class="nav-link-title">Locations</span>
              </div>
              <div class="dropdown-menu" data-bs-popper="none">
                <div class="dropdown-menu-column">
                  <div class="dropend">
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'locations' }"
                    >
                      Locations
                    </router-link>
                    <router-link class="dropdown-item" :to="{ name: 'states' }">
                      States
                    </router-link>
                    <router-link class="dropdown-item" :to="{ name: 'cities' }">
                      Cities
                    </router-link>
                  </div>
                </div>
              </div>
            </li>
            <li
              class="nav-item"
              :class="{ active: $route.name == 'credentials' }"
            >
              <router-link
                v-if="$route.query.userId"
                :href="`${baseUrl}/admin/credentials?userId=${$route.query.userId}&userName=${$route.query.userName}&UserRole=${$route.query.UserRole}`"
                :to="{
                  name: 'credentials',
                  query: {
                    userId: $route.query.userId,
                    userName: $route.query.userName,
                    UserRole: $route.query.UserRole,
                  },
                }"
                class="nav-link"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-square-key"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M14 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M12.5 11.5l-4 4l1.5 1.5"></path>
                  <path d="M12 15l-1.5 -1.5"></path>
                  <path
                    d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"
                  ></path>
                </svg>

                <span class="nav-link-title"> Credentials </span>
              </router-link>
              <router-link
                v-else
                class="nav-link"
                :to="{ name: 'credentials' }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-square-key"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M14 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M12.5 11.5l-4 4l1.5 1.5"></path>
                  <path d="M12 15l-1.5 -1.5"></path>
                  <path
                    d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"
                  ></path>
                </svg>

                <span class="nav-link-title"> Credentials </span>
              </router-link>
            </li>
            <li
              v-if="user.roles[0].slug != 'employee'"
              class="nav-item"
              :class="{
                active: $route.name == 'development_board',
              }"
            >
              <router-link
                v-if="$route.query.userId"
                :href="`${baseUrl}/admin/development_board?userId=${$route.query.userId}&userName=${$route.query.userName}&UserRole=${$route.query.UserRole}`"
                :to="{
                  name: 'development_board',
                  query: {
                    userId: $route.query.userId,
                    userName: $route.query.userName,
                    UserRole: $route.query.UserRole,
                  },
                }"
                class="nav-link"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-clipboard-text"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"
                  ></path>
                  <path
                    d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"
                  ></path>
                  <path d="M9 12h6"></path>
                  <path d="M9 16h6"></path>
                </svg>

                <span class="nav-link-title"> Development Board </span>
              </router-link>
              <router-link
                v-else
                class="nav-link"
                :to="{
                  name: 'development_board',
                }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-clipboard-text"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"
                  ></path>
                  <path
                    d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"
                  ></path>
                  <path d="M9 12h6"></path>
                  <path d="M9 16h6"></path>
                </svg>

                <span class="nav-link-title"> Development Board </span>
              </router-link>
            </li>
            <li
              v-if="
                user.roles[0].slug == 'super.admin' ||
                user.roles[0].slug == 'admin'
              "
              class="nav-item dropdown cursor-pointer"
              :class="{
                active:
                  $route.name == 'support-categories' ||
                  $route.name == 'support-management' ||
                  $route.name == 'message-center',
              }"
            >
              <div class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-info-square-rounded"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 9h.01"></path>
                  <path d="M11 12h1v4h1"></path>
                  <path
                    d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"
                  ></path>
                </svg>

                <span class="nav-link-title">Support</span>
              </div>
              <div class="dropdown-menu" data-bs-popper="none">
                <div class="dropdown-menu-column">
                  <div class="dropend">
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'support-categories' }"
                    >
                      Support Categories
                    </router-link>
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'support-management' }"
                      >Support Topics</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'message-center' }"
                      >Message Center
                    </router-link>
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'notifications' }"
                    >
                      Notification Management
                    </router-link>
                  </div>
                </div>
              </div>
            </li>
            <li
              v-if="user.roles[0].slug == 'super.admin'"
              class="nav-item dropdown cursor-pointer"
              :class="{
                active: $route.name == 'report',
              }"
            >
              <div class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-info-square-rounded"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697"
                  ></path>
                  <path d="M18 14v4h4"></path>
                  <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2"></path>
                  <path
                    d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"
                  ></path>
                  <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                  <path d="M8 11h4"></path>
                  <path d="M8 15h3"></path>
                </svg>
                <span class="nav-link-title">Report</span>
              </div>

              <div class="dropdown-menu" data-bs-popper="none">
                <div class="dropdown-menu-column">
                  <div class="dropend">
                    <!-- <router-link
                      class="dropdown-item"
                      :to="{ name: 'activity-reports' }"
                    >
                      Activity Report
                    </router-link> -->
                    <router-link class="dropdown-item" :to="{ name: 'report' }">
                      Report
                    </router-link>
                  </div>
                </div>
              </div>
            </li>

            <li
              v-if="user.roles[0].slug == 'super.admin'"
              class="nav-item"
              :class="{ active: $route.name == 'skillsets' }"
            >
              <router-link class="nav-link" :to="{ name: 'skillsets' }">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-info-square-rounded"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"
                  ></path>
                  <path
                    d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"
                  ></path>
                  <path d="M9 12l.01 0"></path>
                  <path d="M13 12l2 0"></path>
                  <path d="M9 16l.01 0"></path>
                  <path d="M13 16l2 0"></path>
                </svg>
                <span class="ms-2 nav-link-title"> Skill Set </span>
              </router-link>
            </li>
            <li
              v-if="
                user.roles[0].slug == 'super.admin' ||
                user.roles[0].slug == 'admin'
              "
              class="nav-item"
              :class="{ active: $route.name == 'designations' }"
            >
              <router-link class="nav-link" :to="{ name: 'designations' }">
                <svg
                  color="#000000"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-info-square-rounded"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 4h6v6h-6z"></path>
                  <path d="M14 4h6v6h-6z"></path>
                  <path d="M4 14h6v6h-6z"></path>
                  <path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                </svg>
                <span class="ms-2 nav-link-title"> Designations </span>
              </router-link>
            </li>
            <li class="nav-item" :class="{ active: $route.name == 'tasks' }">
              <router-link class="nav-link" :to="{ name: 'tasks' }">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-article"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"
                  />
                  <path d="M7 8h10" />
                  <path d="M7 12h10" />
                  <path d="M7 16h10" />
                </svg>

                <span class="ms-2 nav-link-title"> Tasks </span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  <div>
    <UploadSkillSetFormModal
      :showing-form="showUploadSkillSetForm"
      :new-skillset="uploadNewSkillSet"
      :log-user="user"
      @user-created="skillSetCreated"
      @close-modal="closeUploadSkillSetForm"
    />
    <UploadDocumentModal
      :showing-form="showUploadDocument"
      @close-modal="showUploadDocument = false"
    />
    <ShowDocumentModal
      :showing-form="showDocuments"
      :all-users-data="allUsers"
      :get-document-requests="getDocumentRequests"
      :documents-requests="allDocumentsRequests"
      @reload-document-requests="getDocumentRequests"
      @close-modal="showDocuments = false"
    />
    <ShowDevelopmentFilesModal
      :showing-form="showDevelopmentFiles"
      :all-users-data="allUsers"
      :get-document-requests="getDocumentRequests"
      :files-requests="allDevelopmentRequests"
      @reload-file-requests="getDocumentRequests"
      @close-modal="showDevelopmentFiles = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { parseDisplayDate } from '@services/common';
import UploadSkillSetFormModal from '@components/UploadSkillSetFormModal.vue';
import UploadDocumentModal from '@components/UploadDocumentModal.vue';
import ShowDocumentModal from '@components/ShowDocumentModal.vue';
import ShowDevelopmentFilesModal from '@components/ShowDevelopmentFilesModal.vue';
import moment from 'moment';
import axios from 'axios';
import {
  Bars3Icon,
  HomeIcon,
  BuildingLibraryIcon,
  InformationCircleIcon,
  DocumentTextIcon,
  XMarkIcon,
  CogIcon,
  ArrowLeftOnRectangleIcon,
  ChevronDownIcon,
  UserCircleIcon,
} from '@heroicons/vue/24/outline';

export default {
  name: 'AppNav',
  components: {
    UploadSkillSetFormModal,
    UploadDocumentModal,
    ShowDocumentModal,
    ShowDevelopmentFilesModal,
    HomeIcon,
    BuildingLibraryIcon,
    InformationCircleIcon,
    DocumentTextIcon,
    Bars3Icon,
    XMarkIcon,
    CogIcon,
    ArrowLeftOnRectangleIcon,
    ChevronDownIcon,
    UserCircleIcon,
  },
  props: {},
  data() {
    return {
      appName: 'Workflow',
      idleTimer: 0,
      // logo: "https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600",
      drop: false,
      baseUrl: window.location.origin,
      assetsDrop: false,
      errors: null,
      allUsers: [],
      success: '',
      loading: false,
      isActive: false,
      loginToUserSession: 1,
      showUploadSkillSetForm: false,
      uploadNewSkillSet: false,
      showUploadDocument: false,
      showDocuments: false,
      showDevelopmentFiles: false,
      allDocumentsRequests: [],
      allDevelopmentRequests: [],
    };
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      roles: 'auth/roles',
      currentUserToken: 'auth/currentUserToken',
      impersonatorToken: 'auth/impersonatorToken',
    }),
    isImpersonating() {
      if (this.currentUserToken && this.impersonatorToken) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.getUsers();
    setInterval(() => {
      this.increaseTimer();
    }, 1000);
    const tablerScript = document.createElement('script');
    tablerScript.type = 'text/javascript';
    tablerScript.setAttribute('src', '../resources/js/tabler.min.js');
    document.head.appendChild(tablerScript);
    document.addEventListener('mousemove', this.resetTimer, false);
    document.addEventListener('mousedown', this.resetTimer, false);
    document.addEventListener('keypress', this.resetTimer, false);
    document.addEventListener('keyup', this.resetTimer, false);
    document.addEventListener('touchmove', this.resetTimer, false);
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      updateTheme: 'auth/theme',
      popToast: 'toast/popToast',
      leaveImpersonatingUser: 'auth/leaveImpersonatingUser',
    }),
    parseDisplayDate,
    increaseTimer() {
      this.idleTimer = parseInt(this.idleTimer) + 1;
      if (this.idleTimer >= 1800) {
        this.logout();
      }
    },
    getDocumentRequests() {
      if (this.user.roles[0].slug == 'super.admin') {
        this.showDocuments = true;
        this.allDocumentsRequests = [];
        axios
          .get(`/api/getDocumentsRequests`)
          .then(({ data }) => {
            this.allDocumentsRequests = data;
          })
          .catch(({ response }) => {});
      } else if (this.user.roles[0].slug == 'admin') {
        this.showDevelopmentFiles = true;
        this.allDevelopmentRequests = [];
        axios
          .get(`/api/getDevelopmentRequests`)
          .then(({ data }) => {
            this.allDevelopmentRequests = data;
          })
          .catch(({ response }) => {});
      }
    },

    resetTimer() {
      this.idleTimer = 0;
    },
    closeDrop() {
      this.drop = false;
      this.assetsDrop = false;
    },
    openDrop() {
      this.drop = true;
      this.assetsDrop = true;
    },
    async toggleTheme() {
      this.loading = true;
      this.errors = null;
      this.success = '';
      try {
        await this.updateTheme({ theme_dark: !this.user.theme_dark }).then(
          (response) => {
            if (
              response &&
              response.data &&
              response.data.user &&
              response.data.user.id
            ) {
              this.popToast({
                message: 'Theme Saved',
                timer: 2000,
                icon: 'success',
              });
            }
          },
        );
      } catch (e) {
        this.errors = e.data;
        this.popToast({
          message: 'Error Updating Theme',
          timer: 5000,
          icon: 'error',
        });
      }
      this.loading = false;
    },
    async leaveImpersonating() {
      try {
        await this.leaveImpersonatingUser().then((response) => {
          //
        });
      } catch (e) {
        this.popToast({
          message: 'Unable To Return To Yourself',
          timer: 5000,
          icon: 'error',
        });
      }
    },
    backToSuperAdmin() {
      axios.get(`api/backToSuperAdmin`).then(({ data }) => {
        window.location.href = window.location.origin;
      });
    },
    triggerSubmitSkillSet() {
      this.showUploadSkillSetForm = true;
      this.uploadNewSkillSet = true;
    },
    closeUploadSkillSetForm() {
      this.showUploadSkillSetForm = false;
      this.uploadNewSkillSet = false;
    },
    unreadBedge() {
      this.user.unread_message_counter = 0;
    },
    timeFormat(date) {
      if (date == null) {
        return;
      } else {
        return moment(date).fromNow();
      }
    },
    notificationDropdownchange() {
      if (
        document
          .getElementById('notification-button')
          .classList.contains('show')
      ) {
        const data = {
          unreadNotificationIds: this.user.unread_notification_ids,
        };
        axios.post('api/updateNotificationsStatus', data).then((response) => {
          this.user.unread_notification_ids = [];
          this.user.notification_unread_counter = 0;
        });
      }
    },
    getUsers() {
      axios.get(`/api/users`).then(({ data }) => {
        this.allUsers = data.allUserRoles;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-pill {
  right: auto !important;
}
.notification-dropdown {
  max-height: 400px;
  width: 450px;
  overflow: scroll;
  margin-top: 5px;
}
@media (max-width: 570px) {
  .notification-dropdown {
    width: 320px;
  }
}
@media (min-width: 570px) and (max-width: 650px) {
  .notification-dropdown {
    width: 385px;
  }
}
@media (min-width: 525px) {
  .notification-dropdown {
    right: 0;
    left: auto;
  }
}
</style>
