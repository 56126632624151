<template>
  <AppModal :show-modal="showingForm" @close-modal="closeModal">
    <template #title> File Requests </template>
    <template #body>
      <div
        class="list-group list-group-flush list-group-hoverable"
        style="overflow: scroll; height: 400px"
      >
        <div
          v-if="!documentsRequests.length"
          colspan="6"
          class="text-center text-muted spinnerParent"
        >
          No Data Found
        </div>
        <div class="col-12">
          <div v-if="documentsRequests.length" class="col-5 float-start">
            <select
              v-model="company_dropdown"
              class="form-select mb-1 float-left"
            >
              <option value="">Sort by Company</option>
              <option
                v-for="userDetail in allUsersData"
                :key="userDetail.user_id"
                :value="userDetail.user_id"
                :class="{
                  'collapse hide': userDetail.slug != 'company',
                }"
              >
                {{ userDetail.company_name }}
              </option>
            </select>
          </div>
          <div v-if="documentsRequests.length" class="float-end col-5 ms-1">
            <input
              ref="userSearchBox"
              v-model="searchUser"
              type="search"
              class="form-control me-1"
              placeholder="Search user…"
            />
          </div>
        </div>
        <div
          v-for="document in documentsRequests"
          :key="document.id"
          class="list-group-item"
          :class="{
            'collapse hide':
              (company_dropdown != '' &&
                company_dropdown != document.get_sender.company_id) ||
              (searchUser != '' &&
                !document.get_sender.first_name
                  .toLowerCase()
                  .match(searchUser.toLowerCase()) &&
                !document.get_sender.last_name
                  .toLowerCase()
                  .match(searchUser.toLowerCase())),
          }"
        >
          <div class="row align-items-center">
            <div class="col text-truncate">
              <div class="col-8 float-start">
                <b>
                  {{ document.get_sender.first_name }}
                  {{ document.get_sender.last_name }}
                </b>
                <div class="col-auto chat-bubble-date col-4 fs-5 float-end">
                  {{ dateFormat(document.created_at) }}
                </div>
                <div
                  v-if="document.get_development"
                  class="col-12 mt-1 text-wrap"
                >
                  <b>Development: </b>
                  {{ document.get_development.description }}
                </div>
                <div class="col-12 mt-1 d-flex flex-wrap">
                  <a
                    v-for="(documentFile, counter) in document.documents"
                    :key="documentFile"
                    :href="`${documentFile}`"
                    target="_blank"
                    class="ps-0 pe-2 pt-1 pb-1"
                    ><span class="badge badge-outline text-cyan">
                      Document {{ counter + 1 }}
                    </span></a
                  >
                </div>
              </div>
              <div class="col-4 text-end float-end">
                <a
                  class="btn btn-success btn"
                  @click="
                    triggerCreateCredential(document.id, document.sender_id)
                  "
                >
                  Approve
                </a>
                <a
                  class="btn btn-danger btn ms-1"
                  @click="triggerRemoveDocumentRequest(document.id)"
                  >Delete</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <CredentialFormModal
        :key="credentialFormKey"
        :message-id="messageId"
        :get-document-requests="getDocumentRequests"
        :showing-form="showCreateCredentialForm"
        :users="users"
        :all-users="allUsers"
        :trucks="trucks"
        :equipments="equipments"
        :credential-form-type="credentialFormType"
        :accept-reason="acceptReason"
        :log-user="logUser"
        :credential-view="credentialView"
        :get-user-ids="getUserids"
        @close-modal="closeForm"
      />
    </template>
  </AppModal>
</template>

<script lang="ts">
import AppModal from '@components/common/AppModal.vue';
import axios from 'axios';
import { mapActions } from 'vuex';
import Errors from '@components/Errors.vue';
import CredentialFormModal from '@components/CredentialFormModal.vue';
import bus from 'vue3-eventbus';
import moment from 'moment';

export default {
  name: 'UploadDocumentModal',
  components: {
    AppModal,
    Errors,
    CredentialFormModal,
  },
  props: {
    getDocumentRequests: { type: Boolean, default: false },
    showingForm: { type: Boolean, default: false },
    documentsRequests: {
      type: Array,
      default() {
        return [];
      },
    },
    allUsersData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      baseUrl: window.location.origin,
      messageId: 0,
      credentialFormType: '',
      company_dropdown: '',
      credentialView: false,
      credentialFormKey: 432489,
      creatingNewCredential: false,
      searchUser: '',
      getUserids: [],
      showCreateCredentialForm: false,
      logUser: [],
      allUsers: [],
      trucks: [],
      equipments: [],
      messages: [],
      acceptReason: '',
    };
  },
  mounted() {
    this.getUsers();
    this.getTrucks();
    this.getEquipments();
  },
  methods: {
    ...mapActions({
      popToast: 'toast/popToast',
    }),
    dateFormat(date) {
      if (date == null) {
        return;
      } else {
        const Odate = new Date(date);
        return Odate.toLocaleString();
      }
    },
    triggerCreateCredential(messageId, sender_id) {
      const self = this;
      const html = `Are you sure you want to create this credential?`;
      const confirmButtonColor = '#2fb344';
      const denyButtonColor = '#777777';
      const confirmButtonText = 'Confirm';
      const denyButtonText = 'Cancel';
      self.$swal
        .fire({
          html,
          showCancelButton: false,
          showDenyButton: true,
          confirmButtonColor,
          denyButtonColor,
          confirmButtonText,
          denyButtonText,
          input: 'text',
          inputLabel: 'Please enter the note',
          inputValue: '',
          inputValidator: (value) => {},
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.acceptReason = result.value;
            this.getUserids = [sender_id];
            this.credentialFormType = 'add';
            this.messageId = messageId;
            this.userId = null;
            this.credentialFormKey += 1;
            this.creatingNewCredential = true;
            this.showCreateCredentialForm = true;
          } else if (result.isDenied) {
            self.popToast({
              message: 'Cancelled',
              timer: 2000,
              icon: 'error',
            });
          }
        });
    },
    triggerRemoveDocumentRequest(documentId) {
      const self = this;
      const html = `Are you sure you want to delete this file request?  If "Delete", then the file request will be eliminated without a chance to recover.`;
      const icon = 'warning';
      const confirmButtonColor = '#FF0000';
      const denyButtonColor = '#777777';
      const confirmButtonText = 'Delete';
      const denyButtonText = 'Cancel';
      self.$swal
        .fire({
          icon,
          html,
          showCancelButton: false,
          showDenyButton: true,
          confirmButtonColor,
          denyButtonColor,
          confirmButtonText,
          denyButtonText,
          input: 'text',
          inputLabel: 'Please enter the reason for rejecting file request',
          inputValue: '',
          inputValidator: (value) => {
            if (!value) {
              return 'You need to enter reason for rejecting file request!';
            }
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            const postData = {
              documentId: documentId,
              reason: result.value,
            };
            axios
              .post('/api/delete-document-request', postData)
              .then(({ data }) => {
                this.$emit('reloadDocumentRequests');
                this.popToast({
                  message: 'File Request Deleted!',
                  timer: 5000,
                  icon: 'success',
                });
              })
              .catch(({ response }) => {
                if (response.status === 422) {
                  const { errors } = response.data;
                  this.errors = errors;
                } else {
                  this.popToast({
                    message: 'Error Deleting Document!',
                    timer: 5000,
                    icon: 'error',
                  });
                }
                this.submitting = false;
              });
          } else if (result.isDenied) {
            self.popToast({
              message: 'Cancelled',
              timer: 2000,
              icon: 'error',
            });
          }
        });
    },
    getUsers() {
      axios
        .get(
          `/api/getUsersForMessages?per_page=all&search_value=` +
            this.search_value,
        )
        .then(({ data }) => {
          this.users = data.user.data;
          this.allUsers = data.user.data;
        });
    },
    getTrucks() {
      axios.get('api/trucks?per=10000000000').then((response) => {
        this.trucks = response.data.data;
      });
    },
    getEquipments() {
      axios.get('api/equipment?per=10000000000').then((response) => {
        this.equipments = response.data.data;
      });
    },
    closeModal() {
      this.$emit('closeModal');
    },
    closeForm() {
      this.credentialView = null;
      this.creatingNewCredential = false;
      this.showCreateCredentialForm = false;
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
:deep() .multiselect-option {
  background: transparent;
  color: #777;
}
:deep() .multiselect-option.is-pointed {
  background: #10b981;
  color: #ffffff;
}
.modal-header {
  background-color: #f7f4f4;
}
.spinnerParent {
  margin-top: 25% !important;
}
</style>
