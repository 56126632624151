// general routes
const Home = () =>
  import(/* webpackChunkName: "js/HomePage" */ '@pages/Home.vue');
const About = () =>
  import(/* webpackChunkName: "js/AboutPage" */ '@pages/About.vue');
const Terms = () =>
  import(/* webpackChunkName: "js/TermsPage" */ '@pages/Terms.vue');
const Dashboard = () =>
  import(/* webpackChunkName: "js/DashboardPage" */ '@pages/Dashboard.vue');
const Login = () =>
  import(/* webpackChunkName: "js/LoginPage" */ '@pages/Login.vue');
const ForgotPassword = () =>
  import(
    /* webpackChunkName: "js/ForgotPasswordPage" */ '@pages/ForgotPassword.vue'
  );
const Survey = () =>
  import(/* webpackChunkName: "js/SurveyPage" */ '@pages/Survey.vue');
const CustomerSurvey = () =>
  import(/* webpackChunkName: "js/SurveyPage" */ '@pages/CustomerSurvey.vue');
const ResetPassword = () =>
  import(
    /* webpackChunkName: "js/ResetPasswordPage" */ '@pages/ResetPassword.vue'
  );
const ApproveSkillSet = () =>
  import(
    /* webpackChunkName: "js/ResetPasswordPage" */ '@pages/ApproveSkillSet.vue'
  );
const Register = () =>
  import(/* webpackChunkName: "js/RegisterPage" */ '@pages/Register.vue');
const VerifyEmail = () =>
  import(/* webpackChunkName: "js/VerifyEmailPage" */ '@pages/VerifyEmail.vue');

const Settings = () =>
  import(
    /* webpackChunkName: "js/SettingsPage" */ '@pages/settings/Settings.vue'
  );
const Profile = () =>
  import(
    /* webpackChunkName: "js/ProfilePage" */ '@pages/settings/Profile.vue'
  );
const Password = () =>
  import(
    /* webpackChunkName: "js/PasswordPage" */ '@pages/settings/Password.vue'
  );
const Account = () =>
  import(
    /* webpackChunkName: "js/AccountPage" */ '@pages/settings/Account.vue'
  );
const NotFound = () =>
  import(/* webpackChunkName: "js/NotFoundPage" */ '@pages/NotFound.vue');

const AdminLayout = () =>
  import(
    /* webpackChunkName: "js/AdminLayoutPage" */ '@/layouts/AdminLayout.vue'
  );
const Admin = () =>
  import(/* webpackChunkName: "js/AdminPage" */ '@/views/admin/Admin.vue');
const Users = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/admin/Users.vue');
const UsersListview = () =>
  import(
    /* webpackChunkName: "js/UsersPage" */ '@/views/admin/Users-ListView.vue'
  );
const ProofSpaceUsers = () =>
  import(
    /* webpackChunkName: "js/UsersPage" */ '@/views/admin/ProofSpaceUsers.vue'
  );
const SupportCategories = () =>
  import(
    /* webpackChunkName: "js/UsersPage" */ '@/views/admin/SupportCategories.vue'
  );

const SupportManagement = () =>
  import(
    /* webpackChunkName: "js/UsersPage" */ '@/views/admin/SupportManagement.vue'
  );
const Support = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/admin/Support.vue');
const MessageCenter = () =>
  import(
    /* webpackChunkName: "js/UsersPage" */ '@/views/admin/MessageCenter.vue'
  );
const Notifications = () =>
  import(
    /* webpackChunkName: "js/UsersPage" */ '@/views/admin/Notifications.vue'
  );
const Tasks = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/admin/Tasks.vue');
const Reports = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/admin/Reports.vue');
const Trucks = () =>
  import(/* webpackChunkName: "js/AssetsPage" */ '@/views/admin/Assets.vue');
const Equipment = () =>
  import(/* webpackChunkName: "js/AssetsPage" */ '@/views/admin/Assets.vue');
const Locations = () =>
  import(/* webpackChunkName: "js/AssetsPage" */ '@/views/admin/Locations.vue');
const Credentials = () =>
  import(
    /* webpackChunkName: "js/AssetsPage" */ '@/views/admin/Credentials.vue'
  );
const DevelopmentBoard = () =>
  import(
    /* webpackChunkName: "js/AssetsPage" */ '@/views/admin/DevelopmentBoard.vue'
  );
const Roles = () =>
  import(/* webpackChunkName: "js/RolesPage" */ '@/views/admin/Roles.vue');
const Permissions = () =>
  import(
    /* webpackChunkName: "js/PermissionsPage" */ '@/views/admin/Permissions.vue'
  );
const AppSettings = () =>
  import(
    /* webpackChunkName: "js/AppSettingsPage" */ '@/views/admin/AppSettings.vue'
  );
const States = () =>
  import(/* webpackChunkName: "js/AssetsPage" */ '@/views/admin/States.vue');
const SkillSets = () =>
  import(/* webpackChunkName: "js/AssetsPage" */ '@/views/admin/SkillSets.vue');
const Designations = () =>
  import(
    /* webpackChunkName: "js/AssetsPage" */ '@/views/admin/Designations.vue'
  );
const Cities = () =>
  import(/* webpackChunkName: "js/AssetsPage" */ '@/views/admin/Cities.vue');
const PhpInfo = () =>
  import(/* webpackChunkName: "js/PhpInfoPage" */ '@/views/admin/PhpInfo.vue');
import { defineAsyncComponent } from 'vue';

import auth from '@/middleware/auth';
import guest from '@/middleware/guest';
import roleAdmin from '@/middleware/roleAdmin';
import roleSuperAdmin from '@/middleware/roleSuperAdmin';
import roleUser from '@/middleware/roleUser';
import roleSuperUser from '@/middleware/roleSuperUser';
import roleManager from '@/middleware/roleUser';
import roleSuperAdminAdminSuperUserManager from '@/middleware/roleSuperAdminAdminSuperUserManager';

export default [
  {
    path: '/',
    redirect: {
      name: 'dashboard',
    },
    // component: () => Dashboard,
    //component: Home,
    name: 'home',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/about',
    component: About,
    name: 'about',
  },
  {
    path: '/terms',
    component: Terms,
    name: 'terms',
  },
  {
    path: '/dashboard',
    component: Dashboard,
    name: 'dashboard',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/admin',
    component: AdminLayout,
    children: [
      {
        path: '',
        name: 'admin',
        component: Admin,
      },
      {
        path: 'users',
        name: 'users',
        component: Users,
      },
      {
        path: 'users-list',
        name: 'users-list',
        component: UsersListview,
      },
      {
        path: 'proofspace-users',
        name: 'proofspace-users',
        component: ProofSpaceUsers,
        meta: {
          middleware: [roleSuperAdmin],
        },
      },
      {
        path: 'support-categories',
        name: 'support-categories',
        component: SupportCategories,
        meta: {
          middleware: [roleSuperAdmin],
        },
      },
      {
        path: 'support-management',
        name: 'support-management',
        component: SupportManagement,
        meta: {
          middleware: [roleSuperAdmin],
        },
      },
      {
        path: 'message-center',
        name: 'message-center',
        component: MessageCenter,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: Notifications,
      },
      {
        path: 'tasks',
        name: 'tasks',
        component: Tasks,
      },
      {
        path: 'support',
        name: 'support',
        component: Support,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'report',
        name: 'report',
        component: Reports,
        meta: {
          middleware: [roleSuperAdmin],
        },
      },
      {
        path: 'roles',
        name: 'roles',
        component: Roles,
      },
      {
        path: 'permissions',
        name: 'permissions',
        component: Permissions,
      },
      {
        path: 'phpinfo',
        name: 'phpinfo',
        component: defineAsyncComponent(() =>
          import('@/views/admin/PhpInfo.vue'),
        ),
      },
      {
        path: 'app-settings',
        name: 'app-settings',
        component: AppSettings,
      },
      {
        path: 'trucks',
        name: 'trucks',
        meta: {
          middleware: [roleSuperAdminAdminSuperUserManager],
        },
        component: Trucks,
      },
      {
        path: 'locations',
        name: 'locations',
        component: Locations,
      },
      {
        path: 'states',
        name: 'states',
        component: States,
        meta: {
          middleware: [roleSuperAdminAdminSuperUserManager],
        },
      },
      {
        path: 'skillsets',
        name: 'skillsets',
        component: SkillSets,
        meta: {
          middleware: [roleSuperAdmin],
        },
      },
      {
        path: 'designations',
        name: 'designations',
        component: Designations,
        meta: {
          middleware: [roleSuperAdminAdminSuperUserManager],
        },
      },
      {
        path: 'cities',
        name: 'cities',
        component: Cities,
        meta: {
          middleware: [roleSuperAdminAdminSuperUserManager],
        },
      },
      {
        path: 'credentials',
        name: 'credentials',
        component: Credentials,
      },
      {
        path: 'development_board',
        name: 'development_board',
        component: DevelopmentBoard,
      },
      {
        path: 'equipment',
        name: 'equipment',
        meta: {
          middleware: [roleSuperAdminAdminSuperUserManager],
        },
        component: Equipment,
      },
    ],
    meta: {
      // middleware: [roleAdmin],
    },
  },
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/approve-skillset',
    component: ApproveSkillSet,
    name: 'approve-skillset',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'forgot-password',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/reset-password/:token',
    props: (route) => ({
      token: route.params.token,
      email: route.query.email,
    }),
    component: ResetPassword,
    name: 'reset-password',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/register',
    component: Register,
    name: 'register',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/survey',
    component: Survey,
    name: 'survey',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/customer-survey',
    component: CustomerSurvey,
    name: 'CustomerSurvey',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/verify-email/:id/:hash',
    props: (route) => ({
      id: route.params.id,
      hash: route.params.hash,
    }),
    component: VerifyEmail,
    name: 'verify-email',
  },
  {
    path: '/settings',
    component: Settings,
    redirect: {
      name: 'profile',
    },
    name: 'settings',
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: 'account',
        component: Account,
        name: 'account',
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'profile',
        component: Profile,
        name: 'profile',
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'password',
        component: Password,
        name: 'password',
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'NotFound',
  },
];
